import { defineComponent } from 'vue'
import { BriefConfigDict, BriefConfigFieldsByDsp, InstructionDsp } from '../../types/instruction_type'

export const briefFieldsConfigMixin = defineComponent({
  methods: {
    async getBriefFieldsConfigByDsp (): Promise<BriefConfigFieldsByDsp> {
      let briefFieldsConfigByDsp = this.$store.getters.getBriefFieldsConfigByDsp

      if (!briefFieldsConfigByDsp || !Object.keys(briefFieldsConfigByDsp).length) {
        await this.$store.dispatch('loadBriefFieldsConfigByDsp')
        briefFieldsConfigByDsp = this.$store.getters.getBriefFieldsConfigByDsp
      }
      return briefFieldsConfigByDsp
    },
    async getBriefFieldsForDsp (dsp: InstructionDsp): Promise<BriefConfigDict> {
      const briefConfigByDsp = await this.getBriefFieldsConfigByDsp()
      return briefConfigByDsp[dsp]
    }
  }
})
