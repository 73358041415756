import { render, staticRenderFns } from "./DV360CampaignSurcoucheV2.vue?vue&type=template&id=5d62ed0d&scoped=true"
import script from "./DV360CampaignSurcoucheV2.vue?vue&type=script&lang=ts"
export * from "./DV360CampaignSurcoucheV2.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d62ed0d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
